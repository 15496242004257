<template lang="html">
    <div class="container main">
        <top-navigation-bar
            title="Regression"
            right-nav="reset"
            @click="doReset"
        />
        <h4 class="my-3 text-center">
            Select the parameter to analyze:
        </h4>
        <b-list-group
            class="bg-white btn-group-toggle btn-group-vertical"
            data-toggle="buttons"
        >
            <label
                v-for="(picker, key) in pickers"
                :key="key"
                class="btn btn-outline-secondary p-3"
                :class="pickerId === key ? ' active': ''"
            >
                <input
                    :id="'picker' + key"
                    v-model="pickerId"
                    type="radio"
                    class="btn-group-toggle"
                    name="pickerId"
                    :value="key"
                    @click="onChangePicker(key)"
                >
                {{ picker }}
            </label>
        </b-list-group>
        <div class="mt-2 mb-2">
            <input-single
                v-model="clInput"
                label="Confidence Level"
                placeholder="%"
                style-class="input-single"
                @enter-pressed="onSubmitEdit()"
            />
            <input-single
                v-model="xInput"
                label="X value"
                placeholder="?"
                style-class="input-single"
                @enter-pressed="onSubmitEdit()"
            />
        </div>
        <highcharts
            v-show="renderChart"
            class="pb-2"
            :options="charts.chartOptions"
        />
        <div class="">
            <h5>{{ textOutput.responseText }} {{ xInput }}</h5>
            <h5>({{ ciOutput.lowerLimit }}, {{ ciOutput.upperLimit }})</h5>
            <h5>{{ textOutput.finalText[0] }} {{ clInput + '%' }} {{ textOutput.finalText[1] }} {{ textOutput.rspOutputText }} {{ textOutput.finalText[2] }} {{ ciOutput.lowerLimit }} {{ textOutput.finalText[3] }} {{ ciOutput.upperLimit }}</h5>
        </div>
    </div>
</template>

<script>
import TopNavigationBar from '@/components/top-navigation-bar.vue'
import InputSingle from '@/components/input-single.vue'
import rData from '@/js/regression/regression-data.js'
import { isEmptyNumsCustom, isErrorCI, isInvalidNums, clearData } from '@/js/lib/input-check.js'
import localStore from '@/store/localStore.js'
import { Chart } from 'highcharts-vue'
import _ from 'lodash'

export default {
    name: 'Yci',
    components: {
        TopNavigationBar,
        InputSingle,
        highcharts: Chart
    },
    data () {
        return {
            clInput: '',
            xInput: '',
            ciOutput: '',
            rpointsOutput: [],
            allData: [],
            renderChart: true,
            pickers: {
                indvResponse: 'Predicted Individual Response',
                meanResponse: 'Mean Response',
            },
            pickerId: 'indvResponse',
            textOutput: {
                rspOutputText: 'Predicted Individual Response',
                responseText: 'C.I for Y if x = ',
                finalText: ['We are ', 'confident that the ', ' is between ', ' and '],
            }
        }
    },
    computed: {
        charts () {
            var { allData, rpointsOutput } = this
            return {
                chartOptions: {
                    xAxis: {
                        title: {
                            text: this.allData.xDesc
                        },
                    },
                    yAxis: {
                        title: {
                            text: this.allData.yDesc
                        },
                    },
                    plotOptions: {
                        spline: {
                            lineWidth: 3,
                            marker: {
                                enabled: false
                            }
                        },
                        line: {
                            lineWidth: 3,
                            marker: {
                                enabled: false
                            }
                        }
                    },
                    series: [{
                        type: 'line',
                        name: 'Regression Line',
                        data: _.get(allData.regrData, 'points', []),
                        color: '#000000',
                        marker: {
                            enabled: false
                        },
                        enableMouseTracking: false
                    }, {
                        type: 'scatter',
                        name: 'Data',
                        data: _.get(allData, 'scatterData', []),
                        color: '#000000',
                        marker: {
                            radius: 4,
                            symbol: 'circle',
                        }
                    },
                    {
                        type: 'spline',
                        name: 'Above',
                        data: _.get(rpointsOutput, 'xyPointsAbove', []),
                        color: '#000000',
                        enableMouseTracking: false
                    },
                    {
                        type: 'spline',
                        name: 'Below',
                        data: _.get(rpointsOutput, 'xyPointsBelow', []),
                        color: '#000000',
                        enableMouseTracking: false
                    },
                    {
                        type: 'line',
                        name: 'Line',
                        data: _.get(rpointsOutput, 'xLine', []),
                        color: 'blue',
                        lineWidth: 4,
                        marker: {
                            enabled: true,
                            symbol: 'circle',
                            lineColor: 'blue',
                            lineWidth: 2
                        }
                    }],
                }
            }
        },
    },
    beforeMount () {
        this.allData = localStore.getStore('regression-data')
    },
    methods: {
        doReset () {
            _.assign(this, clearData(this, ['clInput', 'xInput', 'ciOutput', 'rpointsOutput']))
        },
        onChangePicker (key) {
            this.textOutput.rspOutputText = key === 'indvResponse' ? this.pickers.indvResponse : this.pickers.meanResponse
            this.pickerId = key
            this.onSubmitEdit()
        },
        onSubmitEdit () {
            var { allData, clInput, xInput, pickerId } = this
            if (isEmptyNumsCustom(xInput, clInput)) {
                return
            }
            xInput = _.toNumber(xInput)
            clInput = _.toNumber(clInput)
            if (rData.isInBounds(xInput, allData.items) || isErrorCI(clInput) || isInvalidNums(clInput, xInput)) {
                this.ciOutput = ''
                this.rpointsOutput = []
                return
            }
            const ci = rData.calculateCI(pickerId, allData, clInput, xInput)
            const rpoints = rData.calculateRpoints(pickerId, allData, clInput, xInput)
            this.ciOutput = ci
            this.rpointsOutput = rpoints
        },
    },
}
</script>
